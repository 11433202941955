// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Header */
const Header = () => {
  return (
    <header>
      <h1 style={{ marginBottom: 0 }}>
        <Link
          to="/"
          style={{
            fontSize: 'inherit',
            fontFamily: 'inherit',
            lineHeight: 'inherit',
            margin: 0,
          }}
        >
          Prisma Arts
        </Link>
      </h1>
      <p style={{ marginBottom: 0, marginTop: '1.0044rem' }}>
        Prisma Art curates inspirational contemporary art for art lovers and
        collectors across the globe. Customised art prints, posters,
        merchandises and other art-inspired objects are made on demand,
        featuring original artwork, rare photographs and exquisite creations
        from Auroville, India and beyond.
        <br style={{ display: 'block', marginTop: '1.14rem' }} />
        Explore the rest of our{' '}
        <Link to="https://www.prisma.haus">Prisma Haus</Link>
        &nbsp;–&nbsp;
        <br />
        <Link to="https://books.prisma.haus">Prisma Books</Link>
        &nbsp;•&nbsp;
        <Link to="https://design.prisma.haus">Prisma Design</Link>
        &nbsp;•&nbsp;
        <Link to="https://dreams.prisma.haus">Prisma Dreams</Link>
      </p>
    </header>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header
